import {
    BalButton,
    BalHeading,
    BalStage,
    BalStageBody,
    BalStageImage,
    BalText,
} from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import HeaderImageMobile from "@images/header-image-mobile.jpeg";
import headerImage from "@images/header-image.jpg";
import { Footer } from "@modules/shared/components";
import { PageProps } from "@modules/shared/types";
import { SimulatorLayout } from "@modules/simulator";

const ContactUs: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 500px)");

    return (
        <>
            <SimulatorLayout
                title={t("simulator.wtccontactus.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <BalStage className="header-image">
                    <BalStageImage
                        className="image"
                        srcSet={isMobile ? HeaderImageMobile : headerImage}
                    ></BalStageImage>
                    <BalStageBody>
                        <div></div>
                    </BalStageBody>
                </BalStage>
                <main className="container pt-x-large flex flex-direction-column">
                    <div className="intro flex-1">
                        <BalHeading level="h1">
                            {t("simulator.wtccontactus.title")}
                        </BalHeading>
                        <BalText space="none">
                            {t("simulator.wtccontactus.intro")}
                        </BalText>
                        <BalText space="none">
                            {t("simulator.wtccontactus.contact")}
                        </BalText>
                        <BalButton
                            className="mt-large"
                            rounded
                            color="info"
                            href="https://ford.be"
                        >
                            {t("simulator.wtccontactus.button")}
                        </BalButton>
                    </div>
                    <Footer />
                </main>
            </SimulatorLayout>
        </>
    );
};

export default ContactUs;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
